
import { defineComponent, ref, onMounted, onBeforeMount } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { IStoreProfileData } from './ListPage.vue';

interface IStoreProfileResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreProfileData
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: IStoreProfileData): boolean {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === 0) {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "StoreProfilePost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const initData: IStoreProfileData = {
      name: "",
      points_value: 1
    }
    const postObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IStoreProfileData
    }>({
      isLoading: false,
      noError: true,
      data: { ...initData }
    })
    const loaderStore = useLoaderStore();
    const isRequiredField = ref(false)

    // Functions
    async function onSubmitForm() {
      isRequiredField.value = false
      const { data } = postObject.value
      if(!data)
        return
      if(checkEmptyFields(data))
        return isRequiredField.value = true
      loaderStore.open()
      try {
        const { data } = postObject.value
        if(data) {
          const { name, points_value } = data
          const result: IStoreProfileResponse = await axios.post(`/api/postProfile`, { name, points_value })
          showTimeAlert("Registro criado com sucesso!")
          postObject.value.data = { ...initData }
          return router.push("/perfis-das-lojas")
        }
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
        loaderStore.close()
      }
    }

    return {
      postObject,
      isRequiredField,
      onSubmitForm
    }
  }
})
